import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import Container from '@mui/material/Container'
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  ListItem,
  TextField,
  Skeleton
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTypeNameParam, useGroupIdParam } from 'hooks/useUrlParam'
import { useAuthUser } from 'hooks/useAuthUser'
import {
  Alertflag,
  IoMode,
  registerInspectionTimestamp,
  registerNoteResult,
  registerTimeseriesResult
} from 'api/backendApi'
import { TaskListItem } from 'components/Molecules/TaskListItem'
import { FooterButtons } from 'components/Molecules/FooterButtons'
import { TempRegistrationSnackBar } from 'components/Molecules/TempRegistrationSnackBar'

import { useSetOpenFail, useSetOpenSuccess } from 'contexts/OpenSnackBarContext'
import { useMachineList } from 'hooks/useMachineList'
import { usePlaceName } from 'hooks/usePlaceName'
import { MachineNote, useTempMachineNotes } from 'hooks/useMachineNotes'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useFirstAndLastGroupId } from 'hooks/useFirstAndLastGroupId'
import {
  MachineTask,
  UpdateMachineTaskListProps,
  useMachineTaskList
} from 'hooks/useMachineTaskList'
import CommonDialog from 'components/Molecules/CommonDialog'
import { DebugSnackBar } from 'components/Molecules/DebugSnackBar'

const theme = createTheme()

interface MachineNoteInputProps {
  machineNotes: MachineNote[]
  setMachineNotes: React.Dispatch<React.SetStateAction<MachineNote[]>>
  machineIndex: number
}
/**
 * 備考入力欄のコンポーネント
 *
 * @param {MachineNoteInputProps} props
 * @return {JSX.Element}
 */
const MachineNoteInput: React.FC<MachineNoteInputProps> = (
  props: MachineNoteInputProps
) => {
  if (props.machineNotes?.[props.machineIndex] != null) {
    return (
      <Paper style={{ margin: '4px 0px' }}>
        <ListItem key={`${props.machineIndex}-notes`}>
          <Grid container direction="column">
            <Grid item>
              <InputLabel>備考</InputLabel>
            </Grid>
            <Grid item>
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <TextField
                  value={props.machineNotes[props.machineIndex].note}
                  onChange={(event) => {
                    const newMachineNotes = props.machineNotes.map(
                      (machineNote, index) => {
                        // machineNotesのmachineIndex番目の要素を削除し、代わりにevent.targe.valueを挿入する
                        return index === props.machineIndex
                          ? {
                              ...machineNote,
                              note: event.target.value
                            }
                          : machineNote
                      }
                    )
                    props.setMachineNotes(newMachineNotes)
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </ListItem>
      </Paper>
    )
  } else {
    return <div></div>
  }
}

interface TaskCardsWithSkeletonProps {
  machineTaskList: MachineTask[]
  placeName: string
  updateMachineTaskList: (props: UpdateMachineTaskListProps) => void
  machineNotes: MachineNote[]
  setMachineNotes: React.Dispatch<React.SetStateAction<MachineNote[]>>
}
/**
 * 場所名、点検項目、備考入力欄を表示するコンポーネント
 *
 * @return {JSX.Element}
 */
const TaskCardsWithSkeleton: React.FC<TaskCardsWithSkeletonProps> = (
  props: TaskCardsWithSkeletonProps
) => {
  // 点検項目一覧のloadingが終わった場合
  if (
    props.machineTaskList?.[0]?.tasks != null &&
    props.machineTaskList[0].tasks.length > 0
  ) {
    return (
      <div>
        <Typography variant="h4">{`${props.placeName}`}</Typography>
        {props.machineTaskList.map((machine, machineIndex) => {
          return (
            <div key={machineIndex}>
              <Typography
                variant="h6"
                color={'#005FFF'}
                fontWeight="bold"
              >{`${machine.machineName}`}</Typography>
              {machine.machineMemo != null && (
                <Typography
                  variant="subtitle1"
                  color={'#005FFF'}
                  fontWeight="bolder"
                >{`${machine.machineMemo}`}</Typography>
              )}
              <List>
                {machine.tasks.map((task, taskIndex) => {
                  return (
                    <div key={taskIndex}>
                      <TaskListItem
                        task={task}
                        taskIndex={taskIndex}
                        machineIndex={machineIndex}
                        onTimeseriesChangeFunc={props.updateMachineTaskList}
                      />
                    </div>
                  )
                })}
                <MachineNoteInput
                  machineNotes={props.machineNotes}
                  machineIndex={machineIndex}
                  setMachineNotes={props.setMachineNotes}
                />
              </List>
            </div>
          )
        })}
      </div>
    )
  } else {
    // ローディング中の場合、skeletonを表示させる
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Skeleton variant="text" animation="wave" width={300} height={40} />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={300}
            height={100}
            sx={{ margin: 2 }}
          />
        </Grid>
      </Grid>
    )
  }
}

export const TaskList: React.FC = () => {
  // page遷移用
  const navigate = useNavigate()
  // URLパラメータ
  const { typeName } = useTypeNameParam()

  // contextから以前使用していたidTokenを取得
  // 非同期的に新しいidTokenも取得し、idTokenにsetする
  // これにより、idToken取得を待たずにAPIコールが可能になる
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])

  const { groupId } = useGroupIdParam()
  const { placeName } = usePlaceName({ groupId })
  const { firstGroupId, lastGroupId } = useFirstAndLastGroupId()
  const { machineList } = useMachineList()
  const { machineNotes, setMachineNotes } = useTempMachineNotes({
    machineList
  })
  const { machineTaskList, updateMachineTaskList } = useMachineTaskList({
    machineList
  })
  const [errorMessage, setErrorMessage] = useState('')

  // 入力された値のDB登録を行う
  const registerValue = async (): Promise<void> => {
    // 時系列データの仮登録を行うAPIコール
    const registerTimeseriesToAws = async (): Promise<void> => {
      if (machineTaskList?.length === 0) {
        return
      }

      await Promise.all(
        machineTaskList.map((machine) => {
          return machine.tasks.map(async (task) => {
            // mapなので必ずなにかをreturnする必要あり。valueがない場合はPromise<void>をreturn
            if (task.value === null || task.value === undefined) {
              return await Promise.resolve()
            }
            return await registerTimeseriesResult({
              taskItemId: task.taskItemId,
              resultValue: task.value,
              alertflag:
                task.alertflag != null ? task.alertflag : Alertflag.Off,
              idToken
            })
          })
        })
      )
    }

    // 備考を仮登録するAPIコール
    const registerNotesToAws = async (): Promise<void> => {
      if (machineNotes?.length === 0) {
        return
      }

      await Promise.all(
        machineNotes.map(async (machineNote) => {
          if (machineNote == null || machineNote.note === '') {
            return await Promise.resolve()
          }
          return await registerNoteResult({
            machineId: machineNote.machineId,
            note: machineNote.note,
            idToken
          })
        })
      )
    }

    // 仮登録時刻を登録するAPIコール
    const registerTimestampToAws = async (): Promise<void> => {
      if (Number.isNaN(groupId) || idToken === '') {
        return await Promise.resolve()
      }
      return await registerInspectionTimestamp({
        groupId,
        idToken
      })
    }

    // 時系列データ、備考、時刻を同時に仮登録する
    await Promise.all([
      registerTimeseriesToAws(),
      registerNotesToAws(),
      registerTimestampToAws()
    ]).catch((error) => {
      console.log(error)
      setErrorMessage(error.message)
      throw new Error('register result to aws is failed')
    })
  }

  /**
   * 未入力の点検項目が存在するか確認する
   *
   * @return {boolean}
   */
  const isExistsUnenteredInput = (): boolean => {
    if (machineTaskList.length === 0) {
      throw new Error('machineTaskList is invalid')
    }

    // machineTaskList.machine.tasksの点検項目配列にvalue = undefinedなものがあるかを確認する。
    // 4行下のmachineに対するsome判定が一つでもtrueだった場合、unenteredFlagにはtrueが返る。
    const unenteredFlag = machineTaskList.some((machine) =>
      // 特定のmachineの持つtasks配列においてループを回し、
      // 各taskが以下条件を一つでも満たすかのtrue / falseを判定する。
      machine.tasks.some(
        (task) => task.ioMode === IoMode.Input && task.value == null
      )
    )
    return unenteredFlag
  }

  enum TaskPage {
    PreviousTask = -1,
    NextTask = 1
  }
  interface TransitionToDifferentTaskProps {
    relativePageNumber: TaskPage.PreviousTask | TaskPage.NextTask
  }
  // 仮登録及び次のタスク一覧ページへの遷移を行う
  const transitionToDifferentTask = (
    props: TransitionToDifferentTaskProps
  ): void => {
    if (firstGroupId == null || lastGroupId == null || typeName === '') {
      throw new Error('first / lastGroupId or typeName is null.')
    }
    // 後続のGroupがない状態でcallされた場合、なにもしない
    if (groupId >= lastGroupId) {
      return
    }
    registerValue()
      .then(() => {
        setOpenSuccess(true)
        navigate(
          `/inspectionInput/${typeName}/${groupId + props.relativePageNumber}`
        )
      })
      .catch((error) => {
        console.log(error)
        setOpenFail(true)
      })
  }

  const [isOpenNextTaskCheckScreen, setIsOpenNextTaskCheckScreen] =
    useState(false)
  const openNextTaskCheckScreen = (): void => {
    setIsOpenNextTaskCheckScreen(true)
  }
  const closeNextTaskCheckScreen = (): void => {
    setIsOpenNextTaskCheckScreen(false)
  }
  /**
   * 次のタスクボタンを押した際のハンドラ。
   * 未入力の点検項目がなければ仮登録とページ遷移を行い、存在した場合は確認用のdialogを開く。
   *
   */
  const handleNextTask = (): void => {
    if (isExistsUnenteredInput()) {
      openNextTaskCheckScreen()
    } else {
      transitionToDifferentTask({ relativePageNumber: TaskPage.NextTask })
    }
  }

  // cogniteへの登録が成功/失敗した場合にsnackbarを表示させるsetter
  const setOpenSuccess = useSetOpenSuccess()
  const setOpenFail = useSetOpenFail()
  const [isOpenPreviousTaskCheckScreen, setIsOpenPreviousTaskCheckScreen] =
    useState(false)
  const openPreviousTaskCheckScreen = (): void => {
    setIsOpenPreviousTaskCheckScreen(true)
  }
  const closePreviousTaskCheckScreen = (): void => {
    setIsOpenPreviousTaskCheckScreen(false)
  }
  /**
   * 前のタスクボタンを押した際のハンドラ。
   * 未入力の点検項目がなければ仮登録とページ遷移を行い、存在した場合は確認用のdialogを開く。
   *
   */
  const handlePreviousTask = (): void => {
    if (isExistsUnenteredInput()) {
      openPreviousTaskCheckScreen()
    } else {
      transitionToDifferentTask({ relativePageNumber: TaskPage.PreviousTask })
    }
  }

  const [isOpenGroupListCheckScreen, setIsOpenGroupListCheckScreen] =
    useState(false)
  const openGroupListCheckScreen = (): void => {
    setIsOpenGroupListCheckScreen(true)
  }
  const closeGroupListCheckScreen = (): void => {
    setIsOpenGroupListCheckScreen(false)
  }
  /**
   * グループ一覧タスクボタンを押した際のハンドラ。
   * 未入力の点検項目がなければ仮登録とページ遷移を行い、存在した場合は確認用のdialogを開く。
   *
   */
  const handleGroupList = (): void => {
    if (firstGroupId == null || lastGroupId == null || typeName === '') {
      throw new Error('first / lastGroupId or typeName is null.')
    }
    if (isExistsUnenteredInput()) {
      openGroupListCheckScreen()
    } else {
      transitionToGroupList()
    }
  }
  // 計器一覧ページへの遷移を行う
  const transitionToGroupList = (): void => {
    if (typeName === '') {
      throw new Error('typeName is null.')
    } else {
      registerValue()
        .then(() => {
          setOpenSuccess(true)
          navigate(`/inspectionInput/${typeName}`)
        })
        .catch((error) => {
          console.log(error)
          setOpenFail(true)
        })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            alignItems: 'center'
          }}
        >
          <TaskCardsWithSkeleton
            machineTaskList={machineTaskList}
            placeName={placeName}
            updateMachineTaskList={updateMachineTaskList}
            machineNotes={machineNotes}
            setMachineNotes={setMachineNotes}
          />
          <FooterButtons
            previousTaskButtonHandler={handlePreviousTask}
            nextTaskButtonHandler={handleNextTask}
            groupListButtonHandler={handleGroupList}
          />
          <CommonDialog
            message="未入力の点検項目がありますが、次のタスクページを開きますか？"
            isOpen={isOpenNextTaskCheckScreen}
            doYes={() => {
              closeNextTaskCheckScreen()
              transitionToDifferentTask({
                relativePageNumber: TaskPage.NextTask
              })
            }}
            doNo={closeNextTaskCheckScreen}
          />
          <CommonDialog
            message="未入力の点検項目がありますが、前のタスクページを開きますか？"
            isOpen={isOpenPreviousTaskCheckScreen}
            doYes={() => {
              closePreviousTaskCheckScreen()
              transitionToDifferentTask({
                relativePageNumber: TaskPage.PreviousTask
              })
            }}
            doNo={closePreviousTaskCheckScreen}
          />
          <CommonDialog
            message="未入力の点検項目がありますが、グループ一覧ページを開きますか？"
            isOpen={isOpenGroupListCheckScreen}
            doYes={() => {
              closeGroupListCheckScreen()
              transitionToGroupList()
            }}
            doNo={closeGroupListCheckScreen}
          />
          <TempRegistrationSnackBar />
          <DebugSnackBar message={`${errorMessage}`} />
        </Box>
      </Container>
    </ThemeProvider>
  )
}
